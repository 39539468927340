import {Container, ContainerProps, Flex, Text} from '@chakra-ui/layout';

import {Image} from '@eksab/components/Image';
import {useTranslate} from '@eksab/i18n';

import {Slider} from './Slider';
import {endorsements} from './data';
import checkmarkIcon from './checkmark-icon.png';

export function EndorsedBy(props: ContainerProps) {
  const t = useTranslate();

  return (
    <Container as="section" flexDir="column" alignItems="center" textAlign="center" {...props}>
      <Image src={checkmarkIcon} alt="" width={89} w={[12, 16, 20, '5.5rem']} />

      <Text as="h2" fontWeight="600" fontSize={['xl', null, '2xl']} color="#b6b6b6" my={[1, 1.5, 2]}>
        {t('home.endorsed-by.title')}
      </Text>

      <Text maxW="50rem" fontWeight={{base: 500, lg: 700}} fontSize={['xs', 'md', '2xl', '2rem']} mb={[4, 5, 6]}>
        {t('home.endorsed-by.description')}
      </Text>

      <Slider>
        {endorsements.map((endorsement) => (
          <Flex key={endorsement.endorser} shrink="0" flexDir="column" w="full" scrollSnapAlign="center">
            <Flex
              w="full"
              justify="center"
              pos="relative"
              isolation="isolate"
              rounded="3xl"
              sx={{aspectRatio: '16 / 9'}}
              overflow="hidden"
            >
              <Image
                src={endorsement.url}
                alt={t(endorsement.endorser)}
                width="220"
                height="390"
                w="full"
                h="full"
                objectFit="cover"
                pos="absolute"
                inset="0"
                zIndex="-1"
                filter="auto"
                blur="10px"
                rounded="inherit"
              />

              <Image
                title={t(endorsement.endorser)}
                src={endorsement.url}
                alt={t(endorsement.endorser)}
                width="220"
                height="390"
                objectFit="contain"
                h="full"
                w="auto"
              />
            </Flex>

            <Text
              mt={[2.5, 4, 6]}
              mb={[2, 3, 4]}
              fontSize={['xs', 'md', 'lg', '2xl']}
              fontWeight="700"
              align="center"
              color="#1D1E23"
            >
              {t(endorsement.endorser)}
            </Text>

            <Flex
              alignSelf="center"
              w={['3.125rem', '5rem', '7.875rem']}
              h="1px"
              bgGradient="linear(to-r, transparent 0%, #265334 50%, transparent 100%)"
            />
          </Flex>
        ))}
      </Slider>
    </Container>
  );
}

import {useRouter} from 'next/router';
import {FlexProps, Flex} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons';

import {useTranslate} from '@eksab/i18n';
import {getLanguage} from '@eksab/util';

interface SliderButtonProps extends FlexProps {
  dir: 'start' | 'end';
  sliderRef: React.RefObject<HTMLDivElement>;
}

export const SliderButton = ({dir, sliderRef, ...props}: SliderButtonProps) => {
  const {locale} = useRouter();

  const t = useTranslate();

  const transformDir = (getLanguage(locale) === 'ar' ? -1 : 1) * (dir === 'start' ? -1 : 1);

  return (
    <Flex
      as="button"
      title={t(`app.tooltip.${dir === 'start' ? 'back' : 'next'}`)}
      shrink="0"
      boxSize={[10, 12, 16]}
      bg="gray.500"
      rounded="full"
      align="center"
      justify="center"
      zIndex="1"
      transform="auto"
      transition={['background-color 0.2s ease-in-out, transform 0.2s ease-in-out']}
      _hover={{bg: 'gray.700'}}
      _active={{scale: 0.9}}
      {...props}
      onClick={(e) => {
        sliderRef.current!.scrollLeft =
          sliderRef.current!.scrollLeft + sliderRef.current!.getBoundingClientRect().width * transformDir;

        props.onClick?.(e);
      }}
    >
      <ChevronRightIcon w="50%" h="auto" color="white" transform={`scaleX(${transformDir})`} />
    </Flex>
  );
};

import {useRef} from 'react';
import {FlexProps, Flex, Container} from '@chakra-ui/react';

import {SliderButton} from './SliderButton';

export const Slider = (props: FlexProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  return (
    <Flex as="article" pos="relative" isolation="isolate" align="center" gap="10" maxW="full" {...props}>
      <SliderButton
        dir="start"
        sliderRef={sliderRef}
        pos={['absolute', null, 'static']}
        top="40%"
        translateY="-50%"
        insetStart="-3.5"
      />

      <Container
        ref={sliderRef}
        as="section"
        variant="scroll"
        gap="8"
        px="0"
        mx="0"
        scrollBehavior="smooth"
        sx={{scrollPaddingInline: 0}}
        w="40rem"
      >
        {props.children}
      </Container>

      <SliderButton
        dir="end"
        sliderRef={sliderRef}
        pos={['absolute', null, 'static']}
        top="40%"
        translateY="-50%"
        insetEnd="-3.5"
      />
    </Flex>
  );
};

import type {I18nKey} from '@eksab/i18n';

interface Endorsement {
  endorser: `home.endorsed-by.endorser.${string}` & I18nKey;
  url: string;
}

export const endorsements: Endorsement[] = [
  {
    endorser: 'home.endorsed-by.endorser.essam-elhadary',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5525.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.mohamed-zidan',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5529.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.mohamed-abu-gabl',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5526.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.mahmoud-hamdy',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5530.PNG',
  },
  {
    endorser: 'home.endorsed-by.endorser.marwan-serry',
    url: 'https://eksab-monolith-prod.s3.eu-west-1.amazonaws.com/endorsers/IMG_5528.PNG',
  },
];
